// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-en-datenschutz-tsx": () => import("./../../../src/pages/en/datenschutz.tsx" /* webpackChunkName: "component---src-pages-en-datenschutz-tsx" */),
  "component---src-pages-en-impressum-tsx": () => import("./../../../src/pages/en/impressum.tsx" /* webpackChunkName: "component---src-pages-en-impressum-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mitmachen-tsx": () => import("./../../../src/pages/mitmachen.tsx" /* webpackChunkName: "component---src-pages-mitmachen-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-projekte-euro-art-tsx": () => import("./../../../src/pages/projekte/euro-art.tsx" /* webpackChunkName: "component---src-pages-projekte-euro-art-tsx" */),
  "component---src-pages-projekte-kuk-tsx": () => import("./../../../src/pages/projekte/kuk.tsx" /* webpackChunkName: "component---src-pages-projekte-kuk-tsx" */),
  "component---src-pages-stiftung-tsx": () => import("./../../../src/pages/stiftung.tsx" /* webpackChunkName: "component---src-pages-stiftung-tsx" */)
}

