module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"returnEmptyString":false},"pages":[{"matchPath":"/datenschutz","getLanguageFromPath":false,"languages":["de"]},{"matchPath":"/:lang/datenschutz","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/impressum","getLanguageFromPath":false,"languages":["de"]},{"matchPath":"/:lang/impressum","getLanguageFromPath":true,"languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/jes_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d102cda2893cfa137e34680757c1bc7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
